// @ts-nocheck
import withApollo from 'next-with-apollo';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
const hasuraUrl = 'askeladden-fylla-21.hasura.app/v1/graphql';
import 'tailwindcss/tailwind.css';
import '../styles/globals.css';
import { RecoilRoot } from 'recoil';
import { initializeApp } from 'firebase/app';

initializeApp({
  apiKey: 'AIzaSyDW2euzqa0k5_cFftGxrXvOkVQyC_2QgB4',
  authDomain: 'pinne-for-aco-2021.firebaseapp.com',
  projectId: 'pinne-for-aco-2021',
  storageBucket: 'pinne-for-aco-2021.appspot.com',
  messagingSenderId: '173150344052',
  appId: '1:173150344052:web:1843e88f3563240d439221',
});

function SafeHydrate({ children }) {
  return <div suppressHydrationWarning>{typeof window === 'undefined' ? null : children}</div>;
}
const App = ({ Component, pageProps, apollo }) => (
  <SafeHydrate>
    <RecoilRoot>
      <ApolloProvider client={apollo}>
        <Component class="font-racing-sans" {...pageProps} />
      </ApolloProvider>
    </RecoilRoot>
  </SafeHydrate>
);

export default withApollo(({ initialState }) => {
  const httpLink = new HttpLink({
    uri: 'https://' + hasuraUrl,
  });
  const isBrowser = typeof window !== 'undefined';
  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser,

    link: isBrowser
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
            );
          },
          new WebSocketLink({
            uri: 'wss://' + hasuraUrl,
            options: {
              reconnect: true,
            },
          }),
          httpLink
        )
      : httpLink,
    cache: new InMemoryCache().restore(initialState || {}),
  });
})(App);
