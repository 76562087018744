// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://f8c958ee09954d4480619f01e43732c2@o1019112.ingest.sentry.io/5985071',
  tracesSampleRate: 1.0,
});
